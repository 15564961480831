import { $ } from "@local/ts/lib/patchedJQuery";
import "slick-carousel";

(() => {
  if (typeof $ !== "function") {
    console.error("jQuery is not loaded");
    return;
  }

  if (typeof $.fn["slick"] !== "function") {
    console.error("Slick is not loaded into jquery");
    return;
  }

  // configure sliders
  $(window).on("load", function () {
    $("[data-section-handler='SlideshowSection'] .slideshow").slick({
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 6000, // 6 seconds
      pauseOnFocus: true,
      arrows: false,
      fade: true,
      cssEase: "linear",
      adaptiveHeight: true,
    });

    $("[data-section-handler='ClientMarqueeSection'] .scrolling-section").slick(
      {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 3000,
        cssEase: "linear",
        autoplay: true,
        autoplaySpeed: 0,
        pauseOnHover: false,
        variableWidth: true,
      },
    );

    $("[data-section-handler='TestimonialsSection'] .testimonials").slick({
      dots: true,
      arrows: false,
      slidesToScroll: 3,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToScroll: 2,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1,
            adaptiveHeight: true,
          },
        },
      ],
    });
  });
})();
